<template>
	<div class="app-container applay" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">{{ title }}</div>
		</div>
		<el-form ref="form" :rules="rules" :model="form" label-width="150px">
			<div class="sections-container">
				<el-form-item v-for="(item, i) in properties" class="approval-form-item" :label="item.itemName" :required="!!item.required" :key="i">
					<component
						:placeholder="item.placeholder"
						v-model="item.value"
						v-if="2 === item.componentType"
						:is="ccomponents[item.componentType].name"
						style="width: 50%"
						type="textarea"
					>
						<!-- <el-input type="textarea" :rows="4" style="width: 50%"></el-input> -->
					</component>
					<component
						:placeholder="item.placeholder"
						v-model="item.value"
						v-if="'el-checkbox-group' === ccomponents[item.componentType].name"
						:is="ccomponents[item.componentType].name"
					>
						<el-checkbox :label="citem.id" v-for="(citem, ci) in item.optionMap" :key="ci">{{ citem.value }} </el-checkbox>
					</component>
					<component
						:placeholder="item.placeholder"
						v-model="item.value"
						:name="'radio' + i"
						v-else-if="'el-radio-group' === ccomponents[item.componentType].name"
						:is="ccomponents[item.componentType].name"
						@change="(e) => radioChange(e, item)"
					>
						<el-radio :label="citem.id" v-for="(citem, ci) in item.optionMap" :key="ci">{{ citem.value }} </el-radio>
					</component>
					<component
						v-else-if="ccomponents[item.componentType].name === 'p'"
						:is="ccomponents[item.componentType].name"
						class="form-item-tip"
						>{{ item.defaultValue }}
					</component>
					<div v-else-if="[Departments, Employees, PositionLevels].includes(ccomponents[item.componentType].alias)">
						<component
							v-if="ccomponents[item.componentType].alias == '员工' || approvalType?.subType != 9"
							@click="openInnerDialog(ccomponents[item.componentType].alias, item)"
							type="text"
							:is="ccomponents[item.componentType].name"
							>选择</component
						>
						<p>
							<el-tag :closable="approvalType?.subType != 9" v-if="item.value && item.value.id" @close="removeTag(item)">{{
								item.value && item.value.value
							}}</el-tag>
						</p>
					</div>
					<component
						v-else-if="ccomponents[item.componentType].name === 'el-select'"
						:is="ccomponents[item.componentType].name"
						class="select-form-item"
						:placeholder="item.placeholder"
						v-model="item.value"
						:disabled="approvalType?.subType == 9"
					>
						<el-option v-for="(option, i) in item.optionMap" :label="option.value" :value="option.id" :key="i"></el-option>
					</component>
				</el-form-item>
			</div>
			<div class="sections-container">
				<el-row style="text-align: center; margin-top: -10px">
					<el-button @click="$router.go(-1)">取消</el-button>
					<el-button type="primary" @click="submit">确定</el-button>
				</el-row>
			</div>
		</el-form>
		<el-dialog append-to-body top="10px" width="55%" center :title="innerDialogAction" :visible.sync="innerDialogVisible">
			<div style="height: 80vh">
				<table-position-levels
					v-if="innerDialogAction === PositionLevels"
					:resumeId="String(resumeId)"
					@confirm="confirmSelect"
					:show="innerDialogVisible"
					@cancel="innerDialogVisible = false"
				>
				</table-position-levels>
				<tree-departments
					v-else-if="innerDialogAction === Departments"
					@confirm="confirmSelect"
					:show="innerDialogVisible"
					@cancel="innerDialogVisible = false"
				></tree-departments>
				<table-employees
					v-else-if="innerDialogAction === Employees"
					@confirm="confirmSelect2"
					:show="innerDialogVisible"
					:filter-params="employeesParams"
					@cancel="innerDialogVisible = false"
				>
				</table-employees>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import Vue from 'vue';
import TablePositionLevels from '@/views/staffSystem/components/table-position-levels.vue';
import TreeDepartments from '@/views/staffSystem/components/tree-departments.vue';
import TableEmployees from '@/views/staffSystem/components/table-employees.vue';
import TableContributors from '@/views/staffSystem/components/table-contributors.vue';
import { getApprovalType, approvalGetUserInfo } from '@/api/staffSystem';
import { FileUploadAction } from '@/utils/const';
import { getUrlParams } from '@/utils/util';

const Employees = '员工';
const PositionLevels = '职级体系';
const Departments = '组织';

export default {
	name: 'transfer_apply',
	components: {
		TablePositionLevels,
		TreeDepartments,
		TableEmployees,
		TableContributors
	},
	data() {
		return {
			title: '调岗申请',
			Employees,
			PositionLevels,
			Departments,
			labelWidth: 4,
			approvalInfoName: '',
			approvalTypes: [],
			approvalType: null,
			dialogAction: '',
			dialogVisible: false,
			loading: false,
			innerDialogVisible: false,
			innerDialogAction: '',
			submitting: false,
			uploadQueue: [],
			activeDialogObj: null,
			resumeId: '', // 入职投递者ID
			ccomponents: {
				1: {
					name: 'el-input',
					props: {
						maxlength: 20
					}
				},
				2: {
					name: 'el-input',
					props: {
						rows: 4,
						maxlength: 200,
						type: 'textarea'
					}
				},
				3: {
					name: 'el-date-picker',
					props: {
						type: 'date',
						format: 'yyyy-MM-dd',
						valueFormat: 'yyyy-MM-dd'
					}
				},
				4: {
					name: 'el-date-picker',
					multiple: true,
					props: {
						type: 'daterange',
						format: 'yyyy-MM-dd',
						valueFormat: 'yyyy-MM-dd'
					}
				},
				5: {
					name: 'el-radio-group',
					options: true,
					props: {}
				},
				6: {
					name: 'el-checkbox-group',
					options: true,
					multiple: true,
					props: {
						multiple: true
					}
				},
				7: {
					name: 'el-upload',
					multiple: true,
					files: true,
					props: {
						action: FileUploadAction
					}
				},
				8: {
					name: 'el-input-number',
					props: {
						min: 0,
						max: 10000000
					}
				},
				9: {
					name: 'el-date-picker',
					props: {
						type: 'date',
						format: 'yyyy-MM',
						valueFormat: 'yyyy-MM'
					}
				},
				10: {
					name: 'el-select',
					options: true
				},
				11: {
					name: 'el-date-picker',
					props: {
						type: 'year',
						format: 'yyyy',
						valueFormat: 'yyyy'
					}
				},
				101: {
					name: 'el-button',
					alias: Employees,
					single: true,
					props: {}
				},
				102: {
					name: 'el-button',
					alias: PositionLevels,
					single: true,
					props: {}
				},
				103: {
					name: 'el-button',
					single: true,
					alias: Departments,
					props: {}
				},
				104: {
					// 入职 职级体系
					name: 'el-button',
					alias: PositionLevels,
					single: true,
					props: {}
				},
				105: {
					// 调薪 员工
					name: 'el-button',
					alias: Employees,
					single: true,
					props: {}
				}
			},
			form: {},
			rules: {},
			contrachLoading: false,
			dateRange: '',
			approveStaffs: [], // 新增审批集合
			ccStaffs: [], // 新增抄送人集合
			cpStaffs: [], // 弹窗审批或抄送人列表
			ccStatusType: '',
			ccStatus: false, //
			modifyStatus: 0, // 是否能删除增加审批人;1能，0不能
			ccDialogStatus: false,
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() < new Date(new Date().toLocaleDateString()).getTime();
				}
			},
			properties: [
				{
					itemKey: 'staffId',
					itemName: '员工',
					componentType: 101,
					placeholder: '请选择',
					required: 1,
					defaultValue: null
				},
				{
					itemKey: 'newLevelAndCodeId',
					itemName: '职级体系',
					componentType: 102,
					placeholder: '请选择',
					required: 1,
					defaultValue: null
				},
				{
					itemKey: 'gradeOfPosition',
					itemName: '职等',
					componentType: 10,
					placeholder: '请选择',
					required: 0,
					defaultValue: null
				},
				{
					itemKey: 'newOrgId',
					itemName: '组织',
					componentType: 103,
					placeholder: '请选择',
					required: 1,
					defaultValue: null
				}
			]
		};
	},
	computed: {
		levelList() {
			return this.$store.state.app?.dict?.filter((item) => item.groupId === 'gradeOfPosition') || [];
		},
		params() {
			return JSON.parse(getUrlParams(this.$route.query.params));
		},
		// 是否晋升
		isPromotion() {
			return this.params.type === 'promotion';
		},
		// 是否奖惩
		isReward() {
			return this.params.type === 'reward';
		}
	},
	methods: {
		makeProps(item) {
			if (item.props) {
				return {
					...this.ccomponents[item.componentType].props,
					...item.props
				};
			}
			return this.ccomponents[item.componentType].props;
		},
		async getApprovalType() {
			return getApprovalType().then((res) => {
				if (res._responseStatusCode === 0) {
					this.approvalTypes = res;
					this.changeApprovalProperties({
						subType: 3
					});
				}
			});
		},

		openInnerDialog(action, payload) {
			this.innerDialogAction = action;
			this.innerDialogVisible = true;
			this.activeDialogObj = payload;
		},
		confirmSelect(value) {
			if (value.length > 0) {
				if (this.approvalType?.subType == '3' || this.approvalType?.subType == '6') {
					if (this.$store.state.app.range.staffIds.includes(value[0].id)) {
						this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
						return;
					}
					if (this.$store.state.app.range.performanceEmployeesIds.includes(value[0].id)) {
						this.$message.warning('当前员工绩效考核进行中，请结束后再次申请');
						return;
					}
				}
				if (this.approvalType?.subType == 8) {
					// 入职
					this.resumeId = value[0].id;
				}
				this.activeDialogObj.value = value[0];
				this.innerDialogVisible = false;
			}
		},
		confirmSelect2(value) {
			if (value.length > 0) {
				if (this.approvalType?.subType == '3' || this.approvalType?.subType == '6') {
					if (this.$store.state.app.range.staffIds.includes(value[0].id)) {
						this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
						return;
					}
					if (this.$store.state.app.range.performanceEmployeesIds.includes(value[0].id)) {
						this.$message.warning('当前员工绩效考核进行中，请结束后再次申请');
						return;
					}
				}
				if (this.approvalType?.subType == 8) {
					// 入职
					this.resumeId = value[0].id;
					this.properties[1].value = value[0].resumeInterviewTime;
				}
				this.activeDialogObj.value = value[0];
				this.innerDialogVisible = false;
				if (this.approvalType?.subType == 9) {
					// 调薪
					approvalGetUserInfo({ staffId: value[0].id }).then((res) => {
						for (const key in this.properties) {
							if (
								this.properties[key].itemKey == 'jobEtc' ||
								this.properties[key].itemKey == 'jobEtc' ||
								this.properties[key].itemKey == 'performanceWageBefore' ||
								this.properties[key].itemKey == 'baseWageBefore' ||
								this.properties[key].itemKey == 'postWageBefore'
							) {
								this.properties[key].value = res[this.properties[key].itemKey];
							} else if (this.properties[key].itemKey == 'newOrgId') {
								this.properties[key].value = { id: res.orgId, value: res.orgName };
							} else if (this.properties[key].itemKey == 'newLevelAndCodeId') {
								this.properties[key].value = { id: res.levelAndCodeId, value: res.levelAndCodeValue };
							}
						}
						this.$forceUpdate();
					});
				}
			}
		},

		removeTag(item) {
			this.$set(item, 'value', { id: '', value: '' });
		},
		async getApprovalProperties() {
			this.loading = true;
			this.properties.forEach((v) => {
				if (v.itemName.length > this.labelWidth) {
					this.labelWidth = v.itemName.length;
				}

				this.setBlankValue(v);
				if (this.ccomponents[v.componentType].options) {
					let optionArr = JSON.parse(v.defaultValue) || [];

					if (v.itemKey === 'gradeOfPosition') {
						optionArr = this.levelList.map((it) => ({ id: it.dictId, value: it.dictName }));
					}
					if (v.itemKey === 'type') {
						optionArr = [
							{ id: '1', value: '奖励' },
							{ id: '2', value: '惩罚' }
						];
					}
					if (v.itemKey === 'promotionType') {
						optionArr = [
							{ id: '0', value: '晋升' },
							{ id: '1', value: '调动' }
						];
					}
					v.optionMap = {};
					optionArr.forEach((cv) => {
						v.optionMap[cv.id] = cv;
					});
				}
			});
			return Promise.resolve(true).then(() => (this.loading = false));
		},
		cancel() {
			this.dialogVisible = false;
			this.$emit('update:visible', false);
		},
		findApprovalType(id) {
			const result = this.approvalTypes.find((v) => v.approvalId === id);

			return result;
		},
		async changeApprovalProperties({ subType }) {
			this.employeesParams = {
				notPositive: [1, 2].includes(subType) ? 1 : 0
			};
			this.getApprovalProperties();
		},
		setBlankValue(item) {
			if (this.ccomponents[item.componentType].multiple) {
				this.$set(item, 'value', []);
			} else if (!this.ccomponents[item.componentType].options) {
				this.$set(item, 'value', item.defaultValue || '');
			}
			if (this.ccomponents[item.componentType].files) {
				this.$set(item, 'fileList', []);
			}
		},
		async submit() {
			const valueList = [];

			let err = false;

			const errMsgList = [];

			this.properties.forEach((v) => {
				if (v.required && (!v.value || v.value.length === 0)) {
					errMsgList.push(v.itemName);
					err = true;
					return;
				}
				if (this.ccomponents[v.componentType].options && !this.ccomponents[v.componentType].multiple) {
					valueList.push({
						value: v.value,
						name: v.value ? v.optionMap[v.value].value : '',
						key: v.itemKey
					});
				} else if (this.ccomponents[v.componentType].options && Array.isArray(v.value)) {
					if (v.optionMap) {
						const value = v.value.map((cv) => ({
							id: cv,
							value: v.optionMap[cv]
						}));

						const name = value.map((t) => {
							if (t.value) {
								return t.value.value;
							}
						});

						valueList.push({
							key: v.itemKey,
							value: value.map((t) => t.id).join(','),
							name: name.join(',')
						});
					} else {
						valueList.push({
							key: v.itemKey,
							value: v.value.map((t) => t.id).join(','),
							name: v.value.map((t) => t.value).join(',')
						});
					}
				} else if (
					this.ccomponents[v.componentType].multiple &&
					!this.ccomponents[v.componentType].options &&
					!this.ccomponents[v.componentType].files &&
					Array.isArray(v.value)
				) {
					valueList.push({
						key: v.itemKey,
						value: v.value.join(',')
					});
				} else if (this.ccomponents[v.componentType].files) {
					valueList.push({
						key: v.itemKey,
						value: v.value && v.value.length > 0 ? JSON.stringify(v.value) : '[]'
					});
				} else if (this.ccomponents[v.componentType].single) {
					valueList.push({
						key: v.itemKey,
						value: v.value ? v.value.id : '',
						name: v.value ? v.value.value : ''
					});
				} else {
					valueList.push({
						key: v.itemKey,
						value: v.value
					});
				}
			});
			const _approvalStaffIds = [];

			const _ccStaffIds = [];

			// eslint-disable-next-line guard-for-in
			for (const mkey in this.approveStaffs) {
				_approvalStaffIds.push(this.approveStaffs[mkey].staffId);
			}
			// eslint-disable-next-line guard-for-in
			for (const mkey2 in this.ccStaffs) {
				_ccStaffIds.push(this.ccStaffs[mkey2].staffId);
			}
			if (!err) {
				this.submitting = true;
				this.loading = true;
				let api = '/v1/org/transfer/add';
				const obj = { promotionType: '1' };

				valueList.forEach(({ key, value }) => {
					obj[key] = value;
				});

				// 晋升
				if (this.isPromotion) {
					obj.promotionType = '0';
				}
				// 新增奖惩
				if (this.isReward) {
					api = '/v1/org/staff/reward/punish/add';
					Vue.delete(obj, 'promotionType');
				}

				this.$axios
					.post(api, { request: obj })
					.then((res) => {
						this.submitting = false;
						this.loading = false;
						if (res.code === 0) {
							this.$message({
								type: 'success',
								message: '提交成功'
							});
							this.$router.go(-1);
						}
					})
					.catch(() => {
						this.loading = false;
					});
			} else if (errMsgList.length > 3) {
				this.$message({
					type: 'warning',
					message: '请完整填写必填信息!'
				});
			} else {
				this.$message({
					type: 'warning',
					message: `${errMsgList.join(',')}为必填!`
				});
			}
		},
		// 申请类型变化
		radioChange(e, data) {
			if (data.itemKey === 'promotionType') {
				if (e === '1') {
					this.properties = [
						...this.properties,
						{
							itemKey: 'newOrgId',
							itemName: '组织',
							componentType: 103,
							placeholder: '请选择',
							required: 1,
							defaultValue: null
						}
					];
				} else {
					this.properties = this.properties.filter((it) => it.itemKey !== 'newOrgId');
				}
			}
		}
	},
	mounted() {
		this.getApprovalType();
		if (this.isPromotion) {
			this.title = '调动与晋升申请';
			this.properties = [
				{
					itemKey: 'promotionType',
					itemName: '申请类型',
					componentType: 5,
					placeholder: '请选择',
					required: 1,
					defaultValue: null
				},
				...this.properties.slice(0, -1)
			];
		}
		if (this.isReward) {
			this.title = '奖惩申请';
			this.properties = this.properties.slice(0, 1).concat([
				{
					itemKey: 'type',
					itemName: '奖惩类型',
					componentType: 5,
					placeholder: '请选择',
					required: 1,
					defaultValue: null
				},
				{
					itemKey: 'reason',
					itemName: '原因',
					componentType: 2,
					placeholder: '请输入',
					required: 0,
					defaultValue: null
				}
			]);
		}
	}
};
</script>
<style scoped lang="scss">
.sections-container {
	border-radius: 4px;
	padding: 27px 30px;
	background-color: #fff;
	overflow: hidden;
	margin-bottom: 10px;

	.title-icon {
		font-weight: bold;
		font-size: 16px;
		margin: 0 0 20px 10px;

		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}

	.section-wrap {
		margin-bottom: 20px;
	}
}

.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;

	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}

.applay {
	.el-dialog__header {
		text-align: center;
	}
}

.approvalConChild {
	flex: 1;
	display: flex;
	flex-direction: row;
	// border-bottom: 1px solid #EAEDF1;;
	padding-bottom: 15px;

	.approvalConChild_head {
		margin-top: -12px;
	}

	.approvalConChild_container {
		flex: 1;
		width: 100%;
		margin-right: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-start;
		flex-wrap: nowrap;
		white-space: nowrap;
	}
}

.morye,
.approvalConChild_container {
	.block {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;

		.approvalConChild_container_name {
			line-height: 14px;
			color: #c8c8c8;
			font-size: 14px;
		}

		.delete {
			position: absolute;
			top: -1px;
			right: 0;
			padding: 2px;
		}

		p {
			text-align: center;
		}

		i.el-icon-arrow-right {
			color: #c5cdda;
			font-size: 14px;
			margin-top: -30px;
		}

		i.el-icon-plus {
			color: #c5cdda;
			font-size: 14px;
			margin-top: -30px;
		}
	}
}

.approvalConChild_add {
	border: 1px dashed #409eff;
	color: #409eff;
}

::v-deep.el-form-item__label {
	font-weight: normal;
}

.morye {
	::v-deep.el-dialog__header {
		text-align: center;
		// border-bottom: 1px solid #EAEDF1;
	}

	::v-deep.el-dialog__footer {
		text-align: center;
	}

	.dialog-title {
		font-family: PingFangSC-Medium;
		font-size: 21px;
		color: #409eff;
		letter-spacing: 0;
		line-height: 24px;
	}
}
</style>
